<template>
  <div
    class="bg fill-height"
  >
  <v-container
    fluid
    class="fill-height ma-0"
  >
    <v-row
      align="center"
      justify="center"
    >
      <v-card
        elevation="17"
          raised
          class="pa-5 ma-1"
          width="410px"
      >
        <v-container class="ma-0 logo-wrapper">
            <img src="../assets/mappa_logo.png"/>
          </v-container>

        <v-card-title>Chave de Cadastro Inválida</v-card-title>
        <v-card-text class="pr-0">
          <v-row
            class="ma-0"
          >
            <span
              class="subtitle-1"> Por favor, entre em contato com o nosso time comercial para obter uma chave de
              cadastro válida. <a href="https://horusaeronaves.com/contato/">Entre em contato</a> </span>
          </v-row>
        </v-card-text>
      </v-card>
    </v-row>
  </v-container>
  </div>
</template>

<script>
  export default {
    name: "PostRegister",
  }
</script>

<style scoped lang="scss">
  @import "../sass/mixins";

  .bg {
    background-image: url("../assets/login/background_image.jpg");
    background-size: cover;
  }

  .v-card {
    max-width: 410px;

    @include display-xs-only {
      max-width: 100%;
      width: 100%;
      height: 100%;
    }
  }

  .logo-wrapper {
    text-align: center;
  }

  .logo-wrapper img {
    height: 70px;
  }
</style>
